<script>
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";

// import DropZone from "@/components/widgets/dropZone";
// import datepicker from "vue3-datepicker";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import loaderProcessVue from '../../../components/widgets/loaderProcess.vue';

/**
 * Projects-create component
 */
export default {
  page: {
    title: "Create New Project",
    meta: [{ name: "description", content: appConfig.description }],
  },
  setup() {
    let dropzoneFile = ref("");
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
    };
    return { dropzoneFile, drop, selectedFile, v$: useVuelidate() };
  },
  components: {
    // DropZone,
    // datepicker,
    Layout,
    PageHeader,
    loaderProcessVue,
  },
  data() {
    return {
      title: "Modifier un Programme",
      programme:{
        titre:"",
        description:"",
        duree:"",
      },
      process:false,
      oneProgramme:null,
      items: [
        {
          text: "Pogramme",
          href: "/projects/programmes",
        },
        {
          text: "Modifier",
          active: true,
        },
      ],
      // dropzoneOptions: {
      //   url: "https://httpbin.org/post",
      //   thumbnailWidth: 150,
      //   maxFilesize: 0.5,
      //   headers: { "My-Awesome-Header": "header value" },
      // },
      // daterange: ref(new Date()),
    };
  },
  methods:{
    confirm() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteEvent();
          Swal.fire("Deleted!", "Event has been deleted.", "success");
        }
      });
    },
    imageFileSeelcted(event){
      this.imageSelectFile = event.target.files[0]
    },
    imagePFileSeelcted(event){
      this.imagePSelectFile = event.target.files[0]
    },
    updateProgramme(){
      
      this.process = true
      const self = this
      Api.putFormData("/streamvod/rest/programme/update-programme/" +this.$route.params.id, {
        name: this.programme.titre,
        description: this.programme.description,
        duree: this.programme.duree
      })
      .then(() => {
        self.$router.push('/projects/programmes')
        // this.$router.push({name: "ProgrammesView"});
        // this.$router.push("/projects/programmes");
      }).catch((error) => {
        this.process = false
        Erreur.gestionErreur(error.message)
      })
    },

  },
  async mounted(){
            const result = await Api.get('/streamvod/rest/programme/'+this.$route.params.id)

            this.oneProgramme=result.data.content
            this.programme.description = this.oneProgramme.description
            this.programme.duree = this.oneProgramme.duree
            this.programme.titre = this.oneProgramme.nom
            
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="padding: 25px; width: 65%; margin-left: auto; margin-right: auto; border-radius: 15px;">
          
          <div class="card-body">
            <h4 class="card-title mb-3">{{ $t("pages.updateProgramme.formulaire.title") }}</h4>
            <form>
              <div class="row">
                <div class="form-group col-lg-6 mb-3">
                <label for="projectname" class="col-form-label"
                  >{{ $t("pages.updateProgramme.formulaire.titre.label") }}</label
                >
                <div class="col-lg-12">
                  <input
                    id="projectname"
                    name="projectname"
                    type="text"
                    class="form-control"
                    v-model="programme.titre"
                    :placeholder="$t('pages.updateProgramme.formulaire.titre.placeholder')"
                  />
                </div>
              </div>

              

              <div class="form-group col-lg-6 mb-3">
                <label class="col-form-label">{{ $t("pages.updateProgramme.formulaire.duree.label") }}</label>
                <div class="col-lg-12">
                  <input
                    id="projectname"
                    name="projectname"
                    type="text"
                    v-model="programme.duree"
                    class="form-control"
                    :placeholder="$t('pages.updateProgramme.formulaire.duree.label')"
                  />
                </div>
              </div>
              </div>
              
              <!-- <div class="row mb-3">

                <div class="form-group col-lg-6">
                  <label for="projectbudget" class="col-form-label"
                    >Image</label
                  >
                  <div class="col-lg-12">
                    <input
                      id="projectbudget"
                      name="projectbudget"
                      type="file"
                      @change="imageFileSeelcted($event)"
                      class="form-control"
                      accept=".png, .jpeg, .jpg"
                    />
                  </div>
                </div>

                <div class="form-group col-lg-6">
                  <label for="projectbudget" class="col-form-label"
                    >Image de Plateau</label
                  >
                  <div class="col-lg-12">
                    <input
                      id="projectbudget"
                      name="projectbudget"
                      type="file"
                      @change="imagePFileSeelcted($event)"
                      class="form-control"
                      accept=".png, .jpeg, .jpg"
                    />
                  </div>
                </div>

              </div> -->

              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label"
                  >{{ $t('pages.updateProgramme.formulaire.description.label') }}</label
                >
                <div class="col-lg-12">
                  <textarea
                    id="projectdesc"
                    class="form-control"
                    rows="3"
                    v-model="programme.description"
                    :placeholder="$t('pages.updateProgramme.formulaire.description.placeholder')"
                  ></textarea>
                </div>
              </div>

            </form>
            <!-- <div class="row mb-4">
              <label class="col-form-label col-lg-2">Vidéo</label>
              <div class="col-lg-10">
                <DropZone @drop.prevent="drop" @change="selectedFile" />
                <span class="file-info">Fichier: {{ dropzoneFile.name }}</span>
              </div>
            </div> -->
            <!-- <h4>{{ tags }}</h4>
            <h4>{{ categories }}</h4> -->
            <div class="row">
              <div class="col-lg-10" style="margin-top: 10px; align-items: center; justify-content: center;">
                <button type="submit" class="btn c2play-primary" style="position: relative; left: 50%;" @click="updateProgramme">
                  {{ $t("updateButton") }}
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <loaderProcessVue v-if="process == true"></loaderProcessVue>
    <!-- end row -->
  </Layout>
</template>

<style>
  .select-action{
    display: flex;
    justify-content: space-between;
  }
  .select-action p{
    text-align: center;
    width: 100%;
    font-size: 1.3em;
    cursor: pointer;
    transition: all 0.14s ease;
  }
  .select-action p:hover{
    transform: scale(1.080);
  }
  .borderB{
    border-bottom: 2px solid #556ee6;
  }
</style>